@import 'scheme.scss';

iframe {
    border: none;
    // height: 45px;
    // position: absolute;
    html {
        body {
            background: none;
        }
    }

}

.tape {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
    audio {
        height: 40px;
    }
}
.offline-status {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}
.countdown, .status {
   flex: 1 1 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   text-align: center;
}
.isHidden {
    display: none;
}
.isVisible {
    display: block;
}
.status {
    font-size: 12px;
}
.subline {
    color: rgb(110, 110, 167);
}
.upcoming-date {
    color: $highlight-color;
}